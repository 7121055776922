import React from 'react';
import Typed from 'react-typed';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import { Link } from "gatsby"
import RouterLink from "components/RouterLink"
import Container from 'components/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const images = [
  {
    group: [
      {
        cover:
          '/img/screen/point10.jpg',
        coverDark:
          '/img/screen/point10.jpg',
      },
      {
        cover:
          '/img/screen/screen2.jpg',
        coverDark:
          '/img/screen/screen2.jpg',
      },
      {
        cover:
          '/img/screen/point2.jpg',
        coverDark:
          '/img/screen/point2.jpg',
      },
    ],
  },
  {
    group: [
      {
        cover:
          '/img/screen/screen2.jpg',
        coverDark:
          '/img/screen/screen2.jpg',
      },
      {
        cover:
          '/img/screen/screen3.jpg',
        coverDark:
          '/img/screen/screen3.jpg',
      },
      {
        cover:
          '/img/screen/screen4.jpg',
        coverDark:
          '/img/screen/screen4.jpg',
      },
      {
        cover:
          '/img/screen/point4.jpg',
        coverDark:
          '/img/screen/point4.jpg',
      },
    ],
  },
  {
    group: [
      {
        cover:
          '/img/screen/screen5.jpg',
        coverDark:
          '/img/screen/screen5.jpg',
      },
      {
        cover:
          '/img/screen/screen6.jpg',
        coverDark:
          '/img/screen/screen6.jpg',
      },
      {
        cover:
          '/img/screen/screen7.jpg',
        coverDark:
          '/img/screen/screen7.jpg',
      },
      {
        cover:
          '/img/screen/screen8.jpg',
        coverDark:
          '/img/screen/screen8.jpg',
      },
      {
        cover:
          '/img/screen/point7.jpg',
        coverDark:
          '/img/screen/point7.jpg',
      },
    ],
  },
];
const mock = [
  {
    title: 'FLB.',
		// logo:['/img/flomesh.png','/img/flomesh-white.png'],
    desc: [
			`<div>
The <b>FLB (Flomesh Software Load Balancer)</b> is a software-based application load balancing solution that is a reliable, efficient, and scalable solution to offer both <b>Layer 4</b> and <b>Layer 7</b> load-balancing capabilities. 
<b>FLB</b> has the flexibility to operate in different settings such as physical and virtual machines, cloud hosts, and k8s container platforms.
<ul><li> BGP based L4 Load Balancing</li>
<li> Multi-protocol L7 load balancing</li>
<li> API granular load balancing and policy management</li>
<li> Client load balancing for microservices</li>
<li> Comprehensive web server</li>
<li> DNS load balancing</li>
<li> eBPF Enhanced Networking Capabilities</li>
<li> Multi-tenancy support</li>
<li> Graphical Web Console</li>
</ul></div>`,
			// '<b>Flomesh GUI</b> is an all-in-one console utilizing the <b>DB/Strapi/Vue</b> three-tier architecture. It consists of modules for service mesh, software load balancing, API management, health check, operation and maintenance center, etc. It also provides basic system management functionality.',
			// 'The product supports <b>multi-tenant</b>, <b>multi-cluster</b> and <b>multi-registry</b> service traffic management, provides comprehensive observability and a unified view for services. With its API management, plenty of out-of-the-box pluggable policies are ready for use.'
		],
		btn:'FLB Home',
		path:'/flb',
		prefix: '',
		words: [
			'Deploy to bare metal, virtual machines or Kubernetes', 
			'Comprehensive API management',
			'Reliable, Efficient, and Scalable L4/7 load-balancing',
			'Client-side Load Balancing'
		]
  }
];
const Hero = () => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
		  sx={{
        backgroundImage: `linear-gradient(to bottom, ${alpha(
          theme.palette.background.paper,
          0,
        )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
        backgroundRepeat: 'repeat-x',
        position: 'relative',
      }}
    >
      <Box sx={{ overflow: 'hidden' }} paddingY={{ xs: 0, sm: '4rem', md: '8rem' }}>
        <Container>
					{mock.map((item, i) => (
						<Box key={i}>
							<Box maxWidth={{ xs: 1, sm: '50%' }} sx={{
								minHeight: '250px'
							}}>
								<Typography
									variant="h3"
									color="text.primary"
									gutterBottom
									sx={{
										fontWeight: 700,
									}}
								>
									{ item.logo && (
										<Box
											display={'inline-block'}
											component={'span'}
											height={{ xs: 70, md: 70 }}
											sx={{
												verticalAlign: 'middle',
												position: 'relative',
												marginRight: '20px'
											}}
										>
											<Box
												component={'img'}
												sx={{
													verticalAlign: 'middle'
												}}
												src={
													mode === 'light'
														? item.logo[0]
														: item.logo[1]
												}
												height={1}
											/>
										</Box>
									)}
									<Box
										display={'inline-block'}
										sx={{
											verticalAlign: 'middle'
										}}
									>
										{item.title}
									</Box>
									<br />
									{item.prefix?(
										<Typography
											color="text.primary"
											component={'span'}
											variant="h5"
											sx={{
												marginRight: '10px',
											}}
										>
										{item.prefix}
										</Typography>
									):null}
									{item.words?(
										<Typography
											color={'primary'}
											component={'span'}
											variant="h5"
										>
											<Typed
												strings={item.words}
												typeSpeed={80}
												loop={true}
											/>
										</Typography>
									):null}
								</Typography>
								{item.desc.map((desc, j) => (
									<Typography
										key={j}
										variant="h7"
										component="div"
										color="text.secondary"
										sx={{ fontWeight: 400,marginBottom:'15px' }}
										dangerouslySetInnerHTML={{__html:desc}}
									>
									</Typography>
								))}
								
								<Box
								  flexDirection={{ xs: 'column', sm: 'row' }}
								  alignItems={{ xs: 'stretched', sm: 'flex-start' }}
								  marginTop={4}
								>
								  <Button
								    component="a"
								    variant="contained"
								    color="primary"
								    size="large"
								    fullWidth={isMd ? false : true}
										target="_blank"
								    href="https://github.com/flomesh-io/traffic-guru"
								  >
								    Github
								  </Button>
								  <Box
										display="none"
								    marginTop={{ xs: 2, sm: 0 }}
								    marginLeft={{ sm: 2 }}
								    width={{ xs: '100%', md: 'auto' }}
								  >
								    <Button
								      component="a"
								      href="https://fsm-docs.flomesh.io"
											target="_blank"
								      variant="outlined"
								      color="primary"
								      size="large"
								      fullWidth={isMd ? false : true}
								    >
								      Documentation
								    </Button>
								  </Box>
								</Box>
							</Box>
						</Box>
					))}
        </Container>
        <Box
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
        >
					<Box
						sx={{
							transform: 'rotate(-20deg)',
							display: { xs: 'none', sm: 'block' },
						}}
					>
						<Box
							display={'flex'}
							width={'50rem'}
							left={'50%'}
							top={0}
							position={'absolute'}
							sx={{ transform: 'translate3d(30%, -100%, 0)' }}
						>
							{images.map((item, i) => (
								<Box key={i} marginTop={{ sm: -(i * 16) }} marginX={1}>
									{item.group.map((g, j) => (
										<Box
											key={j}
											padding={1}
											bgcolor={'background.paper'}
											borderRadius={2}
											boxShadow={3}
											marginTop={2}
										>
											<Box
												component={LazyLoadImage}
												effect="blur"
												src={
													theme.palette.mode === 'dark' ? g.coverDark : g.cover
												}
												height={1}
												width={1}
												maxWidth={320}
											/>
										</Box>
									))}
								</Box>
							))}
						</Box>
					</Box>
        </Box>
      </Box>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: '100%',
          marginBottom: theme.spacing(-1),
        }}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
};

export default Hero;
