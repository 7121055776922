/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const mock = [
	{ 
		title:`L4 Load Balancing`,
		primary:'',
		desc:`When operating in centralized deployment mode, a <b>Pipy 4LB</b> utilizes <b>BGP</b> protocol to announce a single IP externally. Data packets are then forwarded by the router to various nodes in the <b>Pipy 4LB</b> cluster based on the <b>ECMP</b> policy announcement information. <b>Pipy</b> then listens on the designated IP ports and distributes received connections to upstream servers based on the selected load balancing policy.`,
		feature:'/img/screen/point9.jpg',
		layout:'left'
	},
	{ 
		title:`L7 Load Balancing`,
		primary:'',
		desc:`<b>FLB</b> offers <b>Layer-7</b> capabilities for load balancing across multiple tenants, clusters, and protocols. It supports a range of protocols such as HTTP/1.x, HTTP/2, DNS, Redis, MQTT, Dubbo, gRPC, Thrift, and TCP short messages.`,
		feature:'/img/screen/point12.jpg',
		layout:'right'
	},
	{ 
		title:`Load Balancing for API`,
		primary:'',
		desc:`<b>FLB</b> offers comprehensive load balancing capabilities at the <b>API</b> level for <b>REST API</b> users. By specifying the API using a combination of "domain name + path + HTTP method," users can manage and balance traffic for that API, like a API Gateway.`,
		feature:'/img/screen/point14.jpg',
		layout:'left'
	},
	{ 
		title:`Client-side Load Balancing`,
		primary:'',
		desc:`<b>Client-side</b> load balancing is a widely used technique in microservices that allows service consumers to access multiple service providers based on load-balancing policies. <br/>
<b>FLB</b> provides client-side load balancing capabilities regardless of whether the microservice consumer process runs on a physical machine, virtual machine, or container. 
`,
		feature:'/img/screen/point8.jpg',
		layout:'right'
	},
	{ 
		title:`DNS Load Balancing`,
		primary:'',
		desc:`<b>FLB</b> provides a <b>DNS</b> module, <b>Pipy</b>, that utilizes <b>PipyJS</b> to develop flexible <b>DNS</b> resolution solutions. <b>FLB</b> intercepts <b>DNS</b> queries and directs them to the <b>Pipy DNS</b> module, which uses specific logic to resolve queries or customize PipyJS scripts based on user requirements. <b>Pipy</b> sends <b>DNS</b> requests to upstream <b>DNS</b> servers,`,
		feature:'/img/screen/point13.jpg',
		layout:'left'
	},
	{ 
		title:`Multi-tenancy`,
		primary:'',
		desc:`<b>FLB</b> control panel allows you full control to add tenants with ease to allow multiple independent instances of one or multiple applications operate in a shared environment.`,
		feature:'/img/screen/point5.jpg',
		layout:'right'
	},
	{ 
		title:`Graphical Web Console`,
		primary:'',
		desc:`The <b>web console</b> allows users to manage most functionalities of the load balancer visually, such as manage control panel components, view system stats, view/query logs, manage SSL certificates and more. Along with multi-tenant load balancing management capabilities, the console also offers <b>customizable dashboards</b>. `,
		feature:'/img/screen/point10.jpg',
		layout:'left'
	},
]
const Teaser = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
	  {mock.map((item, i) => (
			<Box key={i}>
			{( item.layout=='left'||!isMd )
			? <Grid  container spacing={4} direction={isMd ? 'row' : 'column'}>
				<Grid
				  item
				  container
				  alignItems={'center'}
				  xs={12}
				  md={6}
				  data-aos={isMd ? 'fade-right' : 'fade-up'}
				>
				  <Box>
					<Typography
					  sx={{
						textTransform: 'uppercase',
						fontWeight: 'medium',
					  }}
					  gutterBottom
					  color={'secondary'}
					>
					  {item.top}
					</Typography>
					<Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
					  {item.title}.{' '}
					  <Typography color="primary" variant="inherit" component="span">
						{item.primary}
					  </Typography>
					</Typography>
					<Typography variant={'h6'} component={'p'} color={'text.secondary'} dangerouslySetInnerHTML={{__html:item.desc}}>
					</Typography>
				  </Box>
				</Grid>
				<Grid
				  item
				  container
				  justifyContent="center"
				  alignItems="center"
				  xs={12}
				  md={6}
				>
				  <Box maxWidth={490} width={1}>
					<Box
						padding={1}
						bgcolor={'background.paper'}
						borderRadius={2}
						boxShadow={3}
						marginTop={2}
					>
						<Box
							component={LazyLoadImage}
							effect="blur"
							src={item.feature}
							height={1}
							width={1}
							maxWidth={490}
						/>
					</Box>
				  </Box>
				</Grid>
			  </Grid>
			: <Grid key={i} container spacing={4} direction={isMd ? 'row' : 'column'}>
				<Grid
					item
					container
					justifyContent="center"
					alignItems="center"
					xs={12}
					md={6}
				  >
					<Box maxWidth={490} width={1}>
						<Box
							padding={1}
							bgcolor={'background.paper'}
							borderRadius={2}
							boxShadow={3}
							marginTop={2}
						>
							<Box
								component={LazyLoadImage}
								effect="blur"
								src={item.feature}
								height={1}
								width={1}
								maxWidth={490}
							/>
						</Box>
					</Box>
				</Grid>
				<Grid
				  item
				  container
				  alignItems={'center'}
				  xs={12}
				  md={6}
				  data-aos={isMd ? 'fade-left' : 'fade-up'}
				>
				  <Box>
					<Typography
					  sx={{
						textTransform: 'uppercase',
						fontWeight: 'medium',
					  }}
					  gutterBottom
					  color={'secondary'}
					>
					  {item.top}
					</Typography>
					<Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
					  {item.title}.{' '}
					  <Typography color="primary" variant="inherit" component="span">
						{item.primary}
					  </Typography>
					</Typography>
					<Typography variant={'h6'} component={'p'} color={'text.secondary'} dangerouslySetInnerHTML={{__html:item.desc}}>
					</Typography>
				  </Box>
				</Grid>
			  </Grid>
				}
				</Box>
      ))}
    </Box>
  );
};

export default Teaser;
